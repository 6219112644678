/**
 * This file contains all the environment specific configurations.
 */
(function () {
    var naviaqWebApp = angular.module('NaviaqWebApp');


    naviaqWebApp.config(['$localForageProvider', function ($localForageProvider) {
        $localForageProvider.config({
            driver: localforage.LOCALSTORAGE
        });
    }]);

    naviaqWebApp.constant('RovActionValue', 'e2cb9d51-77f8-e411-846e-3a7088915e31');

    naviaqWebApp.constant('environmentConfig', {
        'name': 'preprod',
        'disableCache': true,
        'disableGDPRConsent': false,
    });

    naviaqWebApp.constant('soltransReportId', {
        id: 'AE9B294E-C85B-449C-AC7D-4157EE3C0F04',
    });

    naviaqWebApp.constant('serviceUrls', {
        webApiBaseUrl: 'https://naviaqwebapi-demo.azurewebsites.net/api/v1',
        naviaqHubBaseUrl: 'https://naviaqoperationhub-demo.azurewebsites.net/'
    });
})();
